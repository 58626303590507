export const accountTopUps = {
  landing: {
    nextUp: 'Next up:',
    title: 'Setup your Account balance top-ups',
    howItWorks: 'How it works:',
    automaticallyFund: 'Top-ups will automatically fund your Account when your balance is low',
    scheduledPayments: 'Ensures your scheduled payments never fail due to lack of funds¹',
    adjustAnyTime: 'You can adjust these settings any time',
    footnoteOneBeforeDAA: '¹Some limitations apply. See your',
    footnoteOneAfterDAA: 'for more details.',
  },
} as const
