import { OnboardingHeader, OnboardingHeaderProps, OnboardingHeaderSizeEnum } from '@northone/ui-components'
import { useMobileScreenSize } from '@northone/ui-theme'

type ResponsiveOnboardingHeaderProps = Omit<OnboardingHeaderProps, 'headingLevel' | 'size'> & {
  headingLevel?: OnboardingHeaderProps['headingLevel']
  largerSize?: 'md' | 'lg'
}

const sizeStringToEnumValue = {
  md: OnboardingHeaderSizeEnum.MD,
  lg: OnboardingHeaderSizeEnum.LG,
} as const

export default function ResponsiveOnboardingHeader({
  headingLevel,
  largerSize = 'lg',
  children,
  textAlign,
  testID,
}: ResponsiveOnboardingHeaderProps) {
  const { isMobileSize } = useMobileScreenSize()
  const responsiveHeaderSize = isMobileSize ? OnboardingHeaderSizeEnum.SM : sizeStringToEnumValue[largerSize]

  return (
    <OnboardingHeader
      headingLevel={headingLevel ?? 1}
      size={responsiveHeaderSize}
      testID={testID}
      textAlign={textAlign}
    >
      {children}
    </OnboardingHeader>
  )
}
