import { IIntercomCallback, IIntercomSettings } from '../../types'
import { unauthenticatedUserIntercomSettings } from './intercom-settings'

const IntercomStatus = {
  SHOW: 'show',
  SHOW_MESSAGES: 'showMessages',
  HIDE: 'hide',
  BOOT: 'boot',
  UPDATE: 'update',
} as const

interface IIntercom {
  show: () => void
  showMessages: () => void
  hide: () => void
  boot: (params: IIntercomSettings) => void
  update: (params?: IIntercomSettings) => void
  onUnreadCountChange: (callback: IIntercomCallback) => void
}

export const Intercom: IIntercom = {
  show: () => {
    window.Intercom(IntercomStatus.SHOW)
  },
  showMessages: () => {
    window.Intercom(IntercomStatus.SHOW_MESSAGES)
  },
  hide: () => {
    window.Intercom(IntercomStatus.HIDE)
  },
  boot: (params) => {
    window.Intercom(IntercomStatus.BOOT, {
      ...unauthenticatedUserIntercomSettings,
      email: params.email,
      name: params.name,
      user_id: params.user_id ?? null,
    })
  },
  update: (params) => {
    window.Intercom(IntercomStatus.UPDATE, params)
  },
  onUnreadCountChange: (callback: IIntercomCallback) => {
    window.Intercom('onUnreadCountChange', callback)
  },
}
