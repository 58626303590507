export const PLAID_LINK_TOKEN_LOCALSTORAGE_KEY = 'plaidFundingLinkToken'

export const PLAID_ITEM_CREATE_ERROR_LOCATION_KEY = 'itemCreateError'
export const PLAID_ITEM_CREATE_ERROR_LOCATION_KEY_IDENTITY_FAILURE = 'identity'

export const PLAID_BALANCE_CHECK_MULTIPLIER = 1.5

export const PLAID_BALANCE_CHECK_COUNT_KEY = 'balanceCheckCount'
export const PLAID_BALANCE_CHECK_COUNT_MAXIMUM = 5
export const PLAID_MINIMUM_BALANCE_REQUIRED = 100
