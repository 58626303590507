export const accountUsage = {
  navTitle: 'Account Setup',
  landing: {
    nextUp: 'Next up:',
    title: 'Tell us how you plan to use your Account',
    subtitle: 'You’ll need to provide the following details:',
    businessUsageEstimates:
      'Provide estimates on how your business plans to use Debit cards, Deposits, Checks, ACH payments, and Wire payments¹',
    disclaimerBeforeDAA: '¹See',
    disclaimerAfterDAA:
      'for more details on ACH transfers, same-day ACH transfers, domestic wires, bill pay, and sending physical checks.',
  },
  dollarSignPlaceholder: '$',
  estimateSubtitle: 'Enter your best estimate.',
  debitCardAmount: {
    title: 'Debit cards',
    inputLabel: 'How much do you expect to spend with your NorthOne debit card each month?',
    inputHelper: 'Monthly debit card spending',
  },
  averageDeposit: {
    title: 'Deposits',
    inputLabel: 'How much do you expect your average monthly deposit will be?',
    inputHelper: 'Enter your best estimate.',
  },
  transactionTypes: {
    checkDeposits: 'check deposit',
    achs: 'ACH payment',
    wires: 'wire payment',
  },
  numberOf: {
    title: {
      checkDeposits: 'Checks',
      achs: 'ACH payments',
      wires: 'Wire payments',
    },
    inputLabel: {
      checkDeposits: 'How many checks do you expect to deposit each month?',
      achs: 'How many ACH payments do you expect to send each month?',
      wires: 'How many wire payments do you expect to send each month?',
    },
    subtitle: 'Enter your best estimate.',
    placeholder: '0 per month',
    button: "I don't usually make {{transactionType}}",
  },
  averageAmount: {
    title: 'How much do you expect your average {{transactionType}} will be?',
    subtitle: 'Enter your best guess.',
    inputLabel: 'Average {{transactionType}}',
    placeholder: {
      deposit: '$0 average deposit',
      payment: '$0 average payment',
    },
  },
  loadingScreenTitle: 'Submitting your usage details',
  welcome: {
    title: 'Welcome to NorthOne!',
    subtitle:
      'Your NorthOne Mastercard® Small Business debit card will arrive within {{cardShippingTime}} business days. It will take {{holdFundsDays}} business days for your funds to arrive in your account.\n\nGet started with your NorthOne Account.',
    button: 'Continue',
    waitTimeWarning: 'Launching your account... this may take up to a minute!',
    footer:
      'Banking Services provided by The Bancorp Bank, N.A., Member FDIC. The NorthOne Mastercard® Small Business Debit Card is issued by The Bancorp Bank, N.A., Member FDIC, pursuant to license by Mastercard International Incorporated and may be used everywhere Debit Mastercard is accepted. Mastercard and the circles design are registered trademarks of Mastercard International Incorporated.',
  },
}
