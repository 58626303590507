import { Box, Loader, SizeEnum } from '@northone/ui-components'

import ResponsiveOnboardingHeader from './ResponsiveOnboardingHeader'

export default function LoadingScreen({ title }: { title?: string }) {
  return (
    <Box sx={{ flex: 1, gap: '$8', justifyContent: 'center', alignItems: 'center', alignSelf: 'center' }}>
      {title && <ResponsiveOnboardingHeader>{title}</ResponsiveOnboardingHeader>}

      <Loader size={SizeEnum.LG} />
    </Box>
  )
}
