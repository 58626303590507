export const topUpOptIn = {
  title: 'Setup Account balance top-ups',
  subtitle:
    'Rather than transferring each time your balance runs low, enable auto top-ups to automatically add funds to your NorthOne account.',
  defaultInstitution: 'External Account',
  description: {
    title: 'Auto top-up',
    body: 'NorthOne will automatically transfer {{topUpAmount}} when your balance falls below {{minimumBalanceAmount}}.',
  },
  labels: {
    minimumBalance: 'When balance falls below',
    amount: 'Top up with',
    account: 'From account',
  },
  buttons: {
    skip: 'No, I will monitor my balance manually',
  },
  errorMessages: {
    minimumBalanceOutOfRange: 'Please enter an amount greater than {{minimumFormatted}}.',
    amountOutOfRange: 'Please enter an amount between {{minimumFormatted}} and {{maximumFormatted}}.',
    savingTopUp: 'There was an issue saving top-up configuration. Try again, or contact',
  },
  loading: {
    savingTopUp: 'Setting your Account balance top-up preferences',
  },
}
