export const plaidFunding = {
  error: {
    message: 'There was an issue connecting to your external account. Try again, or contact',
    identityFailure:
      'The account you are trying to connect doesn’t appear to be under any of your  business owner names. Try a different account, or contact',
    customerCare: 'Customer Care.',
  },
  linkAccount: {
    title: 'Connect to Plaid to fund your North One Account',
    whyWeUse: {
      subtitle: 'Why we use Plaid:',
      trusted:
        'Plaid is trusted by thousands of financial institutions for their commitment to security and reliability',
      secure:
        'Plaid uses advanced encryption and does not store your credentials, ensuring your data is handled with the highest level of security',
      reduceWaiting: 'Plaid reduces waiting periods and the risk of data entry errors through instant verification',
    },
    requirements: {
      subtitle: 'Ensure your bank account follows our requirements:',
      accountName: 'The account must be in your name, or the name of one of your co-owners',
      accountBalance: 'The account needs to have a balance of $100 or more',
    },
    disclaimer:
      ' Linking a Plaid account does not constitute an approved application, decisioning will happen after Plaid details are received.',
    fundAccountButton: 'Fund Account',
  },
  amount: {
    title: 'How much do you want to transfer?',
    subtitle:
      'Your funds will only be transferred if your Account is approved and opened. After which, it should take 3 business days for your funds to arrive in your account.',
    inputLabel: 'Transfer amount',
    inputHelper:
      'Enter an amount between {{minimumAmountDisplay}} and {{maximumAmountDisplay}}. Please ensure your existing bank account has sufficient funds for at least $100, and 1.5x the transfer amount.',
    body: 'How much would you like to transfer?',
    fundingLimits: 'Enter an amount between {{minValue}} and {{maxValue}}.',
    minValueError: 'Enter an amount between ${{minValue}} and ${{maxValue}}',
    maxValueError: 'Enter an amount between ${{minValue}} and ${{maxValue}}',
    maxBalanceChecksPerformed:
      'You have reached the amount of maximum attempts for this session. Please try again later or contact',
    lowBalanceError:
      'Your external bank account did not have enough funds for this transfer. Please ensure your existing bank account has sufficient funds of at least $100 and 1.5x the transfer amount.',
    recheckBalance: 'Recheck balance',
    transferLabel: 'Transfer from',
  },
  accountType: {
    title: 'Did you connect a personal or business account?',
    subtitle: 'Let us know what the account type is so that we can ensure your bank transfer is successful.',
    personal: {
      title: 'Personal',
      subtitle: 'Account is under your name',
    },
    business: {
      title: 'Business',
      subtitle: "Account is under your business's name",
    },
  },
  processingFunding: 'Processing your funding',
}
