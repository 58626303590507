/*eslint no-template-curly-in-string: "off"*/
export const funding = {
  landing: {
    nextUp: 'Next up:',
    title: 'Fund your North One Account with Plaid',
    howItWorks: {
      subtitle: 'How it works:',
      plaid: 'We use Plaid to connect to your business or personal bank account',
      makeDeposit: 'Make a deposit into your North One Account',
      useFunds: 'Use these funds to move money or make payments',
    },
  },
  disclaimer: `Banking Services provided by The Bancorp Bank, N.A., Member FDIC. The NorthOne Mastercard® Small Business Debit Card is issued by The Bancorp Bank, N.A., Member FDIC, pursuant to license by Mastercard International Incorporated and may be used everywhere Debit Mastercard is accepted. Mastercard and the circles design are registered trademarks of Mastercard International Incorporated. Linking a Plaid account does not constitute an approved application. Decisioning will happen after Plaid details are received.`,
  mailingAddress: {
    title: 'Where would you like your new debit card to be sent?',
    subTitle: 'Your debit card will be delivered within {{cardShippingTime}} business days of opening your account.',
    businessAddress: 'BUSINESS ADDRESS',
    personalAddress: 'HOME ADDRESS',
  },
  amount: {
    title: 'Enter an amount for your first deposit',
    subtitle: `You'll be able to deposit funds by debit card.`,
    minValueError: 'Enter an amount between ${{minValue}} and ${{maxValue}}',
    maxValueError: 'Enter an amount between ${{minValue}} and ${{maxValue}}',
  },
  cardDetails: {
    title: 'Enter your debit card details',
    subtitle: `Use a debit card associated with either your business or home address. \n\nYour funds will be deposited into your new NorthOne Account.`,
    footer: `By making a deposit, you confirm you've read and agree to the Services Partner Fees in our **Terms of Service**`,
    buttonText: 'Deposit ${{amount}}',
    fields: {
      firstName: {
        label: 'First name',
      },
      lastName: {
        label: 'Last name',
      },
      cardNumber: {
        label: 'Card number',
        placeholder: '1234 5678 9012 3456',
      },
      expirationDate: {
        label: 'Expiration date',
        placeholder: 'MM / YY',
      },
      securityCode: {
        label: 'Security code',
        placeholder: 'CVV',
      },
      billing: {
        header: 'Billing Address',
        subTitle: 'Select the address that is associated with your debit card.',
        businessAddress: 'Business address',
        homeAddress: 'Home address',
        otherAddress: {
          header: 'Add address',
          street: {
            label: 'Street address',
          },
          suite: {
            label: 'Suite, Floor or Unit (Optional)',
            placeholder: 'Suite, Floor or Unit',
          },
          city: {
            label: 'City',
          },
          state: {
            label: 'State',
            placeholder: 'Select',
          },
          zipCode: {
            label: 'ZIP code',
          },
        },
      },
    },
  },
  loading: {
    processing: 'Verifying your card details',
    processingNote: 'Please stay on this screen.\nThis can take up to 2 minutes.',
    success: 'Card details verified!',
    error: {
      title: 'We’re investigating an issue with your deposit',
      subtitle:
        'In the meantime, let’s get back to setting up your account.\n\nQuestions? Reach out to our Customer Care team for more information.',
      helpButton: 'Contact Customer Care',
    },
  },
}
