import { createAction, IActionWithPayload, TActionsUnion } from '../utils'

export enum PlaidFundingActionEnum {
  SET_PLAID_FUNDING_ITEM_ID = 'SET_PLAID_FUNDING_ITEM_ID',
  SET_PLAID_FUNDING_LINK_TOKEN = 'SET_PLAID_FUNDING_LINK_TOKEN',
  SET_PLAID_FUNDING_PUBLIC_TOKEN = 'SET_PLAID_FUNDING_PUBLIC_TOKEN',
  SET_PLAID_FUNDING_ACCOUNT_ID = 'SET_PLAID_FUNDING_ACCOUNT_ID',
  SET_PLAID_FUNDING_INSTITUTION_NAME = 'SET_PLAID_FUNDING_INSTITUTION_NAME',
}

export const plaidFundingActions = {
  setPlaidFundingItemId: (
    itemId: string,
  ): IActionWithPayload<PlaidFundingActionEnum.SET_PLAID_FUNDING_ITEM_ID, string> =>
    createAction(PlaidFundingActionEnum.SET_PLAID_FUNDING_ITEM_ID, itemId),

  setPlaidFundingLinkToken: (
    linkToken: string,
  ): IActionWithPayload<PlaidFundingActionEnum.SET_PLAID_FUNDING_LINK_TOKEN, string> =>
    createAction(PlaidFundingActionEnum.SET_PLAID_FUNDING_LINK_TOKEN, linkToken),

  setPlaidFundingAccountId: (
    accountId: string,
  ): IActionWithPayload<PlaidFundingActionEnum.SET_PLAID_FUNDING_ACCOUNT_ID, string> =>
    createAction(PlaidFundingActionEnum.SET_PLAID_FUNDING_ACCOUNT_ID, accountId),

  setPlaidFundingPublicToken: (
    publicToken: string,
  ): IActionWithPayload<PlaidFundingActionEnum.SET_PLAID_FUNDING_PUBLIC_TOKEN, string> =>
    createAction(PlaidFundingActionEnum.SET_PLAID_FUNDING_PUBLIC_TOKEN, publicToken),

  setPlaidFundingInstitutionName: (
    institutionName: string,
  ): IActionWithPayload<PlaidFundingActionEnum.SET_PLAID_FUNDING_INSTITUTION_NAME, string> =>
    createAction(PlaidFundingActionEnum.SET_PLAID_FUNDING_INSTITUTION_NAME, institutionName),
}

export type TPlaidFundingActions = TActionsUnion<typeof plaidFundingActions>
