export const funnel = {
  phoneNumber: {
    title: 'What is your phone number?',
    label: 'Phone number',
    placeholder: '(XXX) XXX-XXXX',
    errorMessage: 'Enter a valid phone number',
  },
  businessType: {
    title: 'What type of business do you own?',
    label: 'Business type',
    options: {
      corporation: {
        title: 'Corporation',
        description: 'LLC, S-Corp, C-Corp',
      },
      partnership: {
        title: 'Partnership',
        description: 'GP, LP, or similar',
      },
      soleProprietorship: {
        title: 'Sole proprietorship',
        description: 'One person business, or freelancer',
      },
    },
    LLC: 'LLC',
    SCORP: 'S-Corp',
    CCORP: 'C-Corp',
    SOLEPROP: 'Soleprop',
    PARTNERSHIP: 'Partnership',
    FREELANCER: 'Freelancer',
    placeholder: 'Select business type',
  },
  businessRevenue: {
    title: {
      partnershipCorp: "What do you expect {{businessName}}'s total business revenue will be in the next 12 months?",
      soleProp: 'What do you expect your total business revenue will be in the next 12 months?',
    },
    subtitle: 'Enter your best estimate',
    label: 'Business revenue',
    placeholder: '$',
  },
  businessName: {
    title: 'What is the name of your business?',
    label: 'Business name',
    placeholder: 'Example: Acme Corp LLC',
  },
  verifyRegisteredBusinessName: {
    title: 'Is your business name registered with your state?',
    subtitle: 'A registered business name is a trade name or DBA ("Doing Business As") you registered with your state.',
    subtext: 'Your answer won’t impact your ability to apply for a NorthOne Account.',
  },
  verifyUSCitizen: {
    title: 'Are you and your co-owners (if applicable) US citizens or permanent residents?',
    title_soleprop: 'Are you a US citizen or permanent resident?',
    subtitle: 'Anyone who owns 25% or more the business must be a US citizen or permanent resident.',
    selectYes: 'Yes, **all owners** are US citizens or permanent residents',
    selectYes_soleprop: 'Yes, **I am** a US citizen or permanent resident',
    selectNo: 'No, **one or more of the owners** are not US citizens or permanent residents',
    selectNo_soleprop: 'No, **I am not** a US citizen or permanent resident',
    notUSCitizenTitle: "Sorry, we can't serve you",
    notUSCitizenBody:
      "We can't serve customers who are not US citizens or permanent residents right now. We will email you when we are able to serve you.",
    exitApp: 'Exit the application',
  },
  industrySelect: {
    title: 'What industry is your business in?',
    label: 'Industry',
    placeholder: 'Select industry',
    noIndustry: 'My industry is not listed',
  },
  qualifiers: {
    title: 'Please confirm the following',
  },
  citizenship: {
    title: 'Citizenship:',
    declarationNonSoleprop:
      'All owners of 25% or more of your business and anyone with control of your business’ finances must be a US citizen or permanent resident',
    declarationSoleprop: 'To open a North One Account you must be a US citizen or permanent resident',
  },
  prohibitedIndustries: {
    title: 'My business does not engage in:',
    list: {
      MSB: 'Money services businesses (MSB)',
      ATM: 'Private ATM ownership',
      virtualCurrency: 'Bulk exchange of virtual currency',
      marijuana: 'The sale or distribution of marijuana',
      gambling: 'Internet gambling',
    },
    checkBoxTextNonSoleprop:
      "I attest that all owners and people in control of my business' finances are US citizens or permanent residents. Also that my business does not engage with any of the listed activities.",
    checkBoxTextSoleprop:
      'I attest that I am a US citizen or permanent resident. Also that my business does not engage with any of the listed activities.',
  },
  termsAndConditions: {
    title: 'Terms and conditions',
    error: 'Error saving terms',
    checkBoxText: {
      acknowledge: `I acknowledge that I have read and agree to: `,
      esign: `Agreement to Receive Electronic Communications`,
      accountAgreement: `North One Small Business Deposit Account Agreement`,
      privacyCookiePolicy: `Privacy Policy and Cookie Policy`,
      walletServices: 'The Bancorp Bank, N.A., Member FDIC Terms For Card Use With Wallet Services',
    },
  },
  loading: {
    0: 'Saving your information',
    1: 'Starting your business profile',
    2: "Success, let's get started!",
  },
  requiredDocuments: {
    error: {
      title: 'Something went wrong',
      subtitle: 'We weren’t able to pull the list of documents',
    },
    id: {
      title: 'State ID or driver’s license',
      subtitle: 'Photos of the front and back of your unexpired ID. **No scans**.',
    },
    selfie: {
      title: 'Selfie holding your state ID or driver’s license',
      subtitle: 'Make sure that your face is well-lit and the picture on your ID is clear and visible.',
    },
    dba: {
      title: 'Business license or Doing Business As (DBA)',
      subtitle:
        'A document confirming your business, trade, fictitious or assumed name. **EIN registration and IRS documents aren’t accepted**.',
    },
    incorporation: {
      title: 'Formation or incorporation documentation',
      subtitle:
        'For example: articles of incorporation, corporate charter, articles of association, or a certificate of incorporation. **EIN registration and IRS documents aren’t accepted**.',
    },
  },
}
