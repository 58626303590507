import {
  PlaidLinkError,
  PlaidLinkOnEventMetadata,
  PlaidLinkOnExitMetadata,
  PlaidLinkStableEvent,
} from 'react-plaid-link'
import { analytics } from '@/core/analytics/events'

/**
 * For use within onEvent callback on Plaid Link
 *
 * @typedef {Object} options
 * @property {string} eventName
 * @property {number} metadata
 * @property {boolean} businessId
 * @returns {void}
 */
export const handlePlaidAnalytics = ({
  eventName,
  metadata,
}: {
  eventName: PlaidLinkStableEvent | string
  metadata: PlaidLinkOnEventMetadata
}) => {
  const institutionName = metadata.institution_name
  console.log('PLAID EVENT', { eventName, metadata })
  switch (eventName) {
    case 'OPEN':
      analytics.funding.plaid.sdk.open()
      break
    case 'SELECT_INSTITUTION':
      analytics.funding.plaid.sdk.selectInstitution({ institutionName })
      break
    case 'SUBMIT_CREDENTIALS':
      analytics.funding.plaid.sdk.submitCredentials({ institutionName })
      break
    case 'OPEN_OAUTH':
      analytics.funding.plaid.sdk.openOAuth({ institutionName })
      break
    case 'TRANSITION_VIEW':
      if (metadata.view_name === 'CONNECTED') {
        analytics.funding.plaid.sdk.connected()
      }
      if (metadata.view_name === 'SELECT_ACCOUNT') {
        analytics.funding.plaid.sdk.accountSelected({ institutionName })
      }
      break
    case 'HANDOFF':
      analytics.funding.plaid.sdk.handoff()
      break
    case 'ERROR': {
      const error = {
        ...(metadata.error_type ? { type: metadata.error_type } : {}),
        ...(metadata.error_code ? { code: metadata.error_code } : {}),
        ...(metadata.error_message ? { message: metadata.error_message } : {}),
      }
      analytics.funding.plaid.sdk.error({ error })

      break
    }
    default: {
    }
  }
  return
}

/**
 * For use within onExit callback on Plaid Link
 *
 * @typedef {Object} options
 * @property {Object} error
 * @property {number} metadata
 * @property {boolean} businessId
 * @returns {void}
 */
export const handlePlaidExitAnalytics = ({
  error,
  metadata,
}: {
  error: PlaidLinkError | null
  metadata: PlaidLinkOnExitMetadata
}) => {
  //No error indicates regular exit event
  if (error === null && metadata.status) {
    analytics.funding.plaid.sdk.exit({ exitPoint: metadata.status })
  }
  return
}
