import * as Sentry from '@sentry/react'
import {
  AlertTypeEnum,
  Body,
  BodySizeEnum,
  Box,
  DiagonalArrowIcon,
  ICONS,
  InlineAlert,
  LegalDisclaimerText,
  Link,
  PrimaryButton,
  SectionHeader,
  SectionHeaderSizeEnum,
} from '@northone/ui-components'
import { useMobileScreenSize } from '@northone/ui-theme'
import { useEffect, useState } from 'react'
import { usePlaidLink } from 'react-plaid-link'
import { useDispatch } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'

import Fieldset from '@/components/Fieldset'
import GoBackButton from '@/components/GoBackButton'
import { ListItem } from '@/components/ListItem'
import { Intercom } from '@/core/intercom'
import { plaidFundingActions } from '@/core/redux/plaid-funding/actions'
import { getPlaidFundingState } from '@/core/redux/plaid-funding/selectors'
import { useAppSelector } from '@/core/redux/utils'
import { useCreateLinkTokenMutation } from '@/generated/graphql'
import { useOnboardingTranslations } from '@/i18n/locales/en/en'
import { BaseContentLayout } from '@/layouts/BaseContentLayout'
import { Pathname } from '@/routes/constants'

import { handlePlaidAnalytics, handlePlaidExitAnalytics } from '../analytics'
import {
  PLAID_ITEM_CREATE_ERROR_LOCATION_KEY,
  PLAID_ITEM_CREATE_ERROR_LOCATION_KEY_IDENTITY_FAILURE,
  PLAID_LINK_TOKEN_LOCALSTORAGE_KEY,
} from '../constants'

const ErrorBanner = ({ error }: { error: string }) => {
  const t = useOnboardingTranslations()
  const customerCare = t('plaidFunding.error.customerCare')

  return (
    <InlineAlert type={AlertTypeEnum.Error} showIcon>
      <Body size={BodySizeEnum.SM} color="$error5">
        {error} <Link onPress={Intercom.show}>{customerCare}</Link>
      </Body>
    </InlineAlert>
  )
}

const usePlaidItemCreateErrorMessage = (plaidItemErrorType: string | undefined) => {
  const t = useOnboardingTranslations()
  const itemCreateErrorMessage = t('plaidFunding.error.message')
  const identityFailureErrorMessage = t('plaidFunding.error.identityFailure')

  if (!plaidItemErrorType) return undefined
  if (plaidItemErrorType === PLAID_ITEM_CREATE_ERROR_LOCATION_KEY_IDENTITY_FAILURE) {
    return <ErrorBanner error={identityFailureErrorMessage} />
  }
  return <ErrorBanner error={itemCreateErrorMessage} />
}

export default function PlaidFundingLinkExternalAccountScreen() {
  const t = useOnboardingTranslations()
  const { isMobileSize } = useMobileScreenSize()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const businessId = useAppSelector((state) => state.application.businessId)
  const { linkToken } = useAppSelector(getPlaidFundingState)

  const [isPreparingToNavigate, setIsPreparingToNavigate] = useState(false)
  const plaidItemErrorType: string | undefined =
    location.state && Object.keys(location.state).includes(PLAID_ITEM_CREATE_ERROR_LOCATION_KEY)
      ? location.state[PLAID_ITEM_CREATE_ERROR_LOCATION_KEY]
      : undefined

  const plaidItemCreateErrorMessage = usePlaidItemCreateErrorMessage(plaidItemErrorType)

  const [createLinkTokenMutation, { loading: linkTokenLoading }] = useCreateLinkTokenMutation({
    variables: {
      businessId,
      redirectUri: `${window.location.origin}/oauth/`,
    },
    onCompleted: (data) => {
      const linkToken = data.createLinkToken?.data.linkToken
      if (!linkToken) return
      dispatch(plaidFundingActions.setPlaidFundingLinkToken(linkToken))
      localStorage.setItem(PLAID_LINK_TOKEN_LOCALSTORAGE_KEY, linkToken)
    },
    onError: (error) => {
      Sentry.captureException(error)
    },
  })

  useEffect(() => {
    createLinkTokenMutation()
  }, [])

  const { open } = usePlaidLink({
    token: linkToken ? linkToken : null,
    onSuccess: (public_token, metadata) => {
      setIsPreparingToNavigate(true)
      const plaidAccountId = metadata.accounts[0]?.id
      if (!plaidAccountId) return
      dispatch(plaidFundingActions.setPlaidFundingAccountId(plaidAccountId))
      dispatch(plaidFundingActions.setPlaidFundingPublicToken(public_token))
      navigate(Pathname.ACCOUNT_FUNDING_ACCOUNT_TYPE)
    },
    onEvent: (eventName, metadata) => {
      handlePlaidAnalytics({ eventName, metadata })
      const institutionName = metadata.institution_name
      if (institutionName) {
        dispatch(plaidFundingActions.setPlaidFundingInstitutionName(institutionName))
      }
    },
    onExit: (error, metadata) => {
      handlePlaidExitAnalytics({ error, metadata })
    },
  })

  const onPressContinueButton = () => open()
  const onPressGoBackButton = () => navigate(Pathname.ACCOUNT_FUNDING)

  const title = t('plaidFunding.linkAccount.title')
  const whyWeUseSubtitle = t('plaidFunding.linkAccount.whyWeUse.subtitle')
  const trusted = t('plaidFunding.linkAccount.whyWeUse.trusted')
  const secure = t('plaidFunding.linkAccount.whyWeUse.secure')
  const reduceWaiting = t('plaidFunding.linkAccount.whyWeUse.reduceWaiting')
  const requirementsSubtitle = t('plaidFunding.linkAccount.requirements.subtitle')
  const accountName = t('plaidFunding.linkAccount.requirements.accountName')
  const accountBalance = t('plaidFunding.linkAccount.requirements.accountBalance')
  const legalDisclaimer = t('disclaimers.standard') + ' ' + t('plaidFunding.linkAccount.disclaimer')
  const fundAccountButton = t('plaidFunding.linkAccount.fundAccountButton')

  return (
    <BaseContentLayout
      headingText={title}
      primaryButton={
        <PrimaryButton
          testID="link-external-account-continue"
          onPress={onPressContinueButton}
          loading={isPreparingToNavigate || linkTokenLoading}
          disabled={linkTokenLoading}
          icon={DiagonalArrowIcon}
          fullWidth
        >
          {fundAccountButton}
        </PrimaryButton>
      }
      secondaryButton={<GoBackButton testID="link-external-account-go-back" onPress={onPressGoBackButton} fullWidth />}
      alertComponent={plaidItemCreateErrorMessage ?? null}
      showProgressAutoSavedNote={isMobileSize}
      disclaimerComponent={<LegalDisclaimerText color={'$charcoal5'}>{legalDisclaimer}</LegalDisclaimerText>}
    >
      <Box sx={{ gap: '$8' }}>
        <Fieldset>
          <SectionHeader headingLevel={2} size={SectionHeaderSizeEnum.MD}>
            {whyWeUseSubtitle}
          </SectionHeader>
          <Box sx={{ flexDirection: 'column', gap: '$2' }}>
            <ListItem icon={ICONS.PlaidIcon} iconColor="$charcoal7">
              {trusted}
            </ListItem>
            <ListItem icon={ICONS.LockIcon} iconColor="$charcoal7">
              {secure}
            </ListItem>
            <ListItem icon={ICONS.ProtectedIcon} iconColor="$charcoal7">
              {reduceWaiting}
            </ListItem>
          </Box>
        </Fieldset>
        <Fieldset>
          <SectionHeader headingLevel={2} size={SectionHeaderSizeEnum.MD}>
            {requirementsSubtitle}
          </SectionHeader>

          <Box sx={{ flexDirection: 'column', gap: '$2' }}>
            <ListItem icon={ICONS.IdentificationIcon} iconColor="$charcoal7">
              {accountName}
            </ListItem>
            <ListItem icon={ICONS.MoneyIcon} iconColor="$charcoal7">
              {accountBalance}
            </ListItem>
          </Box>
        </Fieldset>
      </Box>
    </BaseContentLayout>
  )
}
