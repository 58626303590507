import { Intercom } from '.'
import { config } from '../../utils/environment'

export const unauthenticatedUserIntercomSettings = {
  app_id: config.intercomAppId,
  user_id: '',
  email: '',
  name: '',
  alignment: 'right',
  horizontal_padding: 20,
  vertical_padding: 20,
}

export const displayIntercomWidget = (toDisplay: boolean): void => {
  Intercom.update({
    hide_default_launcher: !toDisplay,
  })
}
