export const applicationStatus = {
  onboardingPaused: {
    title: "We're verifying your account information",
    emailPromise: 'You will receive an email at ',
    timePromise: ' soon with an update to your application.',
  },
  approved: {
    title: 'Congratulations!',
    subTitle: 'You’ve been approved. Now let’s work on setting up your new NorthOne Account.',
    buttonText: 'Get started',
  },
  manualReview: {
    title: `Check your email\nto upload your documents`,
    subTitle: `We've sent last steps to **{{email}}**`,
    body: `(subject line: **Your NorthOne Account: more information required**).\n\nYour debit card will only be charged after your account is approved and opened.`,
    helpButton: "I didn't get an email",
    resendButton: 'Resend email',
  },
  manualReviewMoreInfo: {
    title: "Didn't get an email? Send us your documents",
    subtitle: 'Send a copy of the following documents to **account-services@northone.com:**',
    document1: "Your unexpired State ID OR Driver's License",
    document2: {
      corpAndPartner: {
        title: 'Formation or incorporation documentation',
        subtitle:
          'For example, articles of incorporation, corporate charter, articles of association, or a certificate of incorporation',
      },
      soleAndFreelancer: {
        title: '**If applicable**: business license OR Doing Business As (DBA)',
        subtitle:
          'This can also be your trade name, or fictitious or assumed name registration document, confirming your business name.',
      },
    },
    footer: "Once we've received your documents, we'll email you about your account.",
  },
  rejected: {
    title: 'Check your email for an update',
    subTitle: `A status update has been sent to`,
  },
  waitlist: {
    title: 'Congratulations!',
    body: `You’ve been approved to join thousands of small business owners across the country that use NorthOne to manage their banking.
    \nWe’ve sent an email to **{{email}}** with more details on how to finish setting up your account.`,
    learnMore: 'Learn more',
  },
}
