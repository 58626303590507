export const applicationForm = {
  start: {
    llcPartnership: {
      firstTime: {
        title: 'Tell us about your business',
        subtitle:
          'To open a bank account for **{{businessName}}**, you will need to provide:\n\n• Information about the owner(s), such as name, address, and SSN\n• Information about the business, such as EIN\n\nYour application will save as you go.',
      },
      hasStartedApplication: {
        title: `Let's finish up!`,
        subtitle:
          'To finish opening a bank account for **{{businessName}}**, you will need to confirm:\n\n• Information about the owner(s), such as name, address, and SSN\n• Information about the business, such as EIN\n\nYour application will save as you go.',
      },
      mobile: {
        title: "Let's finish up!",
        subtitle: `**{{businessName}}** is just a few minutes away from America's fastest bank account.`,
      },
    },
    freelanceSoleProp: {
      firstTime: {
        title: 'Tell us about your business',
        subtitle:
          'To open a bank account, you will need to provide:\n\n• Your personal information, such as name, address, and SSN\n• Your business information, such as address and phone number\n\nYour application will save as you go.',
      },
      hasStartedApplication: {
        title: `Let's finish up!`,
        subtitle:
          'To finish opening a bank account, you will need to confirm:\n\n• Your personal information, such as name, address, and SSN\n• Your business information, such as address and phone number\n\nYour application will save as you go.',
      },
      mobile: {
        title: "Let's finish up!",
        subtitle: "You're just a few minutes away from America's fastest bank account.",
      },
    },
    letsGo: "Let's go",
    continue: 'Continue application',
    patriotAct: `**Important information about procedures for opening a new account:**\nTo help the government fight the funding of terrorism and money laundering activities, federal law requires all financial institutions to obtain, verify, and record information that identifies each person who opens an Account. What this means for you: When you open an Account, we will ask for your name, address, date of birth, and other information that will allow us to identify you. We may also ask to see a copy of your driver’s license or other identifying documents`,
  },
  aboutYourBusiness: {
    title: 'About your business',
    nameSubheadingSoleProp: 'What is your business’ legal name?',
    nameSubNoteSoleProp: 'If your business name isn’t registered with your state, leave blank.',
    nameSubheading: 'What is the name of your business?',
    descriptionSubheading: 'Describe what your business does in detail',
    legalBusinessName: 'Legal business name',
    businessName: 'Business name',
    tradeNameLabel: 'Trade name or DBA (optional)',
    tradeNameTip: 'A trade name or DBA (“Doing Business As”) is another name your company uses to operate.',
    descriptionLabel: 'Description',
    descriptionTip: 'For example, who are your customers and what products or services do you provide?',
    descriptionPlaceholder: 'Example: Marketing agency specializing in web development and logo design',
    einSubheading: 'Employer Identification Number (EIN)',
    einTip:
      'Your business was assigned an EIN by the IRS. It is also sometimes known as a Federal Tax Identification Number. ',
    improvement: {
      descriptionSubheading: 'What does your business do?',
      subDescription: 'This helps us understand how to support you.',
    },
  },
  businessContactInfo: {
    title: 'How would you like us to reach your business?',
    businessContactSubheading: 'What are your business contact details?',
    sameAddressCheckboxLabel: 'My business address is the same as my home address',
    titleLong: 'Business contact information',
    phoneInputLabel: 'Business phone number',
    addressInputLabel: 'Business street address',
    suiteNumberInputLabel: 'Apartment, suite, etc (optional)',
    businessOperation: {
      title: 'How many years has your business operated at this address?',
      subHeading: 'Round up to the nearest full year.',
      label: 'Years at this address',
      helper: 'Round up to the nearest full year',
      placeholder: 'Number of years',
    },
    socialMediaBlock: {
      title: 'Add {{businessName}}’s website or social media accounts (optional)',
      yourBusiness: 'your business',
      website: 'Website',
      facebook: 'Facebook',
      twitter: 'Twitter',
      instagram: 'Instagram',
      linkedin: 'LinkedIn',
      other: 'Other',
    },
    addressInputs: {
      street: 'Street address',
      suite: 'Suite, floor or unit',
      city: 'City',
      state: 'Select state',
      stateLabel: 'State',
      zip: 'Zip code',
      searchPlaceholder: 'Search for your physical business address',
      enterManually: 'Enter address manually',
    },
  },
  fullTimeEmployees: {
    subHeading: 'How many full-time employees does your business have, including you?',
    label: 'Full-time employees',
  },
  businessOperations: {
    title: 'Business operations',
  },
  corpInfo: {
    title: 'Corporation info',
    titleLong: 'Corporation information',
    corpTypeSubheading: 'What type of corporation is {{businessName}}?',
    ein: 'EIN',
    einError: 'For security reasons, we do not save your EIN. Please re-enter it.',
    einDetail:
      'The EIN can be found in a confirmation letter from the IRS or on tax returns and payment slips. You can also call the IRS at (800) 829-4933.',
  },
  ownerProfiles: {
    title: 'Owner profiles',
    titleSoleProp: 'Owner profile',
    subtitle:
      'Add the profiles of all legal business owners who own at least 25% of your business, or have control of your company’s finances.',
    labels: {
      email: 'Email address',
      phoneNumber: 'Phone number',
      birthdate: 'Date of birth',
      ssn: 'SSN',
      ssnDetail: 'Social Security Number (SSN)',
      address: 'Home address',
      firstName: 'Legal first name',
      lastName: 'Legal last name',
      jobTitle: 'Job title',
      improvment: {
        ssn: 'Your Social Security Number',
        ssnSubtitle:
          'We use your SSN to verify your identity per federal regulations, and store it securely. Your credit score will not be affected.',
      },
    },
    placeholders: {
      firstName: 'First name',
      lastName: 'Last name',
      ssn: 'XXX-XX-XXXX',
      phoneNumber: '(XXX) XXX-XXXX',
      jobTitle: 'Example: CEO',
      email: 'Email address',
    },
    ssnError: 'For security reasons, we do not save your SSN. Please re-enter it.',
    primaryOwner: {
      saveError: 'Save your owner profile to continue.',
      personalInfo: 'Your personal information',
      contactInfo: 'Your contact information',
      position: 'Tell us about your position at {{businessName}}',
      positionDefault: 'Tell us about your position in your business',
      button: 'Save my profile',
      ssnToolTip: 'Your SSN will be transmitted and stored securely.',
      addressPlaceholder: 'Search for your home address',
      ssnError: 'For security, we did not save your SSN. Please re-enter it.',
    },
    ubo: {
      personalInfo: 'Co-owner personal information',
      contactInfo: 'Co-owner contact information',
      position: `Tell us about {{name}}'s position at {{businessName}}`,
      positionDefault: `Tell us {{name}}'s position in the business`,
      coOwner: 'this co-owner',
      button: 'Save co-owner profile',
      ssnToolTip: `Your co-owner's SSN will be transmitted and stored securely.`,
      addressPlaceholder: `Search for your co-owner's address`,
      ssnError: `For security, we did not save your co-owner's SSN. Please re-enter it.`,
      saveError: 'Save this owner profile to continue.',
    },
    controlPerson: {
      title: 'Designate your financial administrator',
      subtitle:
        "A financial administrator is the person primarily responsible for the business' finances. They can be an executive officer or senior manager (e.g., Chief Executive Officer, Chief Financial Officer, Chief Operating Officer, Managing Member, General Partner, President, Vice President, Treasurer), or any other individual who regularly performs similar functions. See your ",
      accountAgreementLinkText: 'Deposit Account Agreement',
      forMoreDetails: ' for more details.',
      otherCandidate: {
        actionItemHeader: 'My financial administrator is not an owner',
        actionItemDescription: 'Add a financial administrator',
        formTitle: "Complete your financial administrator's profile",
      },
      personalInfo: 'Personal information',
      contactInfo: 'Contact information',
      subheading: 'Who is the control person of {{businessName}}?',
      theBusiness: 'the business',
      otherControlPerson: 'Somebody else is the control person',
      me: 'I am the control person',
      inputSubtitle:
        'The control person is the person with significant or primary responsibility for managing the legal entity noted.',
      button: 'Save control person profile',
      add: 'Add control person',
      error: 'You must specify a control person to continue',
      ssnToolTip: `Your control person's SSN will be transmitted and stored securely.`,
      addressPlaceholder: `Search for your control person's address`,
      ssnError: `For security, we did not save your control person's SSN. Please re-enter it.`,
      saveError: `Save your control person's profile to continue.`,
    },
    ownership: {
      title: 'Who owns {{businessName}}?',
      subtitle: 'Regulations require applicants to list everyone who owns 25% or more of the business.',
      onlyOwner: 'I am the only owner',
      multipleOwners: 'I have co-owners',
    },
    addAnother: 'Add a legal co-owner',
  },
  percentOwnership: {
    title: 'What percentage of {{businessName}} does each person own?',
    theBusiness: 'the business',
    subtitle: 'Regulations require applicants to list everyone who owns 25% or more of the business.',
    total: 'Total ownership',
    errorOver100: "Total ownership can't exceed 100%",
    errorUnder1: 'Enter a number greater than 0 for each owner',
  },
  continue: {
    nearlyThere: 'Nearly there!',
    profileComplete: 'Business profile complete',
    progressBarTitle: 'Profile complete',
    button: 'Continue',
    view: 'View',
    fieldsMissingTitle: 'It looks like you missed some required fields:',
    missingOneField: 'It looks like you have missed a required field:',
    fieldsCompleted: '{{completed}} out of {{total}} complete',
    applicationCompleteTitle: "Let's get going, {{name}}!",
    applicationCompleteBody:
      'We’ll need to review the information you’ve provided in your application.\n\nYour credit score will not be affected.',
    checkBoxText:
      'I, **{{name}}**, hereby certify, to the best of my knowledge, that the information provided above is complete and correct.',
    complete: 'Complete!',
    percentOwnershipWarning: {
      title: 'Have you added all legal business owners?',
      subtitle: 'Regulations require applicants to list everyone who owns 25% or more of the business.',
      yes: 'Yes, I have',
      no: 'Go back',
    },
  },
  loading: {
    submitting: 'Submitting your business profile',
    reviewing: 'Reviewing your business profile',
    settingUpCard: 'Setting up your new debit card',
  },
  submitError: {
    title: 'Something went wrong',
    subtitle: 'Something went wrong while submitting your application. Please try again.',
    button: 'Submit again',
  },
}
