import { useEffect } from 'react'
import { usePlaidLink } from 'react-plaid-link'
import { useNavigate } from 'react-router-dom'

import LoadingScreen from '@/components/LoadingScreen'
import { PLAID_OAUTH_STATE_ID_URL_PARAM } from '@/utils/constants'

import { Pathname } from '../constants'
import { PLAID_LINK_TOKEN_LOCALSTORAGE_KEY } from '../funding/constants'

export default function OAuthPage() {
  const navigate = useNavigate()

  const onPlaidLinkSuccess = () => {
    navigate(Pathname.ACCOUNT_FUNDING_ACCOUNT_TYPE)
  }
  const linkToken = localStorage.getItem(PLAID_LINK_TOKEN_LOCALSTORAGE_KEY)
  const urlParams = new URLSearchParams(window.location.search)
  const oauthStateIdParam = urlParams.get(PLAID_OAUTH_STATE_ID_URL_PARAM)

  const { open, ready, error } = usePlaidLink({
    token: linkToken,
    receivedRedirectUri: window.location.href,
    onSuccess: onPlaidLinkSuccess,
  })

  useEffect(() => {
    if (!linkToken || !oauthStateIdParam) throw new Error('Missing linkToken or oauthStateIdParam')
    if (!ready) return
    if (error) navigate(Pathname.ACCOUNT_FUNDING_AMOUNT)
    open()
  }, [error, open, ready, linkToken, navigate, oauthStateIdParam])

  return <LoadingScreen />
}
