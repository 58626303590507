import { SecondaryButton } from '@northone/ui-components'

import { useOnboardingTranslations } from '@/i18n/locales/en/en'

export interface GoBackButtonProps {
  testID: string
  onPress: () => void
  disabled?: boolean
  loading?: boolean
  fullWidth?: boolean
}

export default function GoBackButton({
  testID,
  onPress,
  loading = false,
  disabled = false,
  fullWidth = false,
}: GoBackButtonProps) {
  const t = useOnboardingTranslations()
  const text = t('button.goBack')

  return (
    <SecondaryButton testID={testID} onPress={onPress} disabled={disabled} loading={loading} fullWidth={fullWidth}>
      {text}
    </SecondaryButton>
  )
}
