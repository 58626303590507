import { analytics } from '@northone/segment-js'
import { SegmentMiddleware } from '@northone/segment-js/dist/src/types'

export interface ISegmentSource {
  key: string
  value: string
}

type SegmentEventContext = 'properties' | 'traits' | 'context'

export const addSourceToAnalytics = (field: ISegmentSource, eventContext: SegmentEventContext): void => {
  if (!window.analytics) return
  analytics().addSourceMiddleware(addFieldToContext(field, eventContext))
}

export const addFieldToContext = (field: ISegmentSource, eventContext: SegmentEventContext): SegmentMiddleware =>
  function addField({ payload, next }): void {
    try {
      const requestObj = payload.obj[eventContext]
      if (requestObj && field.value) {
        requestObj[field.key] = field.value
      }
    } catch (error) {
      if (error instanceof Error) {
        console.error(error.message)
      } else {
        console.error(error)
      }
    }
    next(payload)
  }
