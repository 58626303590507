export const gettingStarted = {
  title: "You'll need the following information to complete your application:",
  business: {
    subtitle: 'Business information:',
    employerId: 'Employer Identification Number (Corporations and Partnerships only)',
    revenue: 'Revenue estimates for the next 12 months',
  },
  personal: {
    subtitle: 'Personal information for each business owner who owns 25% or more of the business:',
    ssn: 'Social Security Number (SSN)',
    identification: 'Birthday, home address, phone number, email',
    ownership: 'Ownership percentage',
  },
  modal: {
    EIN: {
      title: 'How to find your Employer Identification Number (EIN):',
      checkLetter: 'Check the confirmation letter you received from the IRS when you applied for an EIN.',
      checkDocuments:
        'Look at old federal tax returns, official IRS tax notices, business licenses and permits, bank statements, business loan applications, credit reports, or payroll paperwork.',
      contactInstruction:
        'Contact the IRS if you have lost or misplaced your EIN. You can call the Business & Specialty Tax Line at',
      contactNumber: '800-829-4933.',
    },
    SSN: {
      title: 'How to find your Social Security Number (SSN):',
      whereToFindSSN: 'Your nine-digit Social Security Number is located on your Social Security Card.',
      contactInstruction:
        'Contact the SSA if you have lost or misplaced your SSN. You can request a new card online at ',
      contactLink: 'www.ssa.gov/myaccount/replacement-card.html',
    },
  },
  mobileWebviewRedirectModal: {
    header: 'Continue in your browser',
    body: "You'll be asked to log in to your North One account again through your browser to proceed with your application.",
    button: 'Launch browser',
  },
}
