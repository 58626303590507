export const inAppDocumentCapture = {
  documentList: 'Document list',
  uploadDocuments: {
    title: 'Gather these documents to upload',
    verify: 'We need these documents to verify your application.',
    informationRequestReason:
      'We’re requesting this information because you’re applying for a NorthOne Account. **Learn more**.',
    secureDocumentStorage: 'Your documents will be stored securely.',
    progressBarTitle: 'Upload documents',
    imReady: "I'm ready",
    documentBullets: {
      id: {
        heading: 'State ID or driver’s license',
        subHeading: 'Photos of the front and back of your unexpired ID. No scans.',
      },
      formationOrIncorporation: {
        heading: 'Formation or incorporation documentation',
        subHeading:
          'For example: Articles of incorporation, corporate charter, articles of association, or a certificate of incorporation. EIN documents are not accepted.',
      },
      businessLicense: {
        heading: 'Business license or Doing Business As (DBA)',
        subHeading: 'A document confirming your business name or trade. EIN documents are not accepted.',
      },
    },
  },
  intercom: {
    title: 'Upload your documents in the chat',
    secureDocumentStorage: 'Your documents will be stored securely.',
    button: 'I’m done uploading',
    viewDocumentList: 'View document list',
  },
  disclaimer: {
    title: 'Patriot Act Disclaimer',
    info: '**Important information about procedures for opening a new account**',
    patriotAct1:
      'To help the government fight the funding of terrorism and money laundering activities, federal law requires all financial institutions to obtain, verify, and record information that identifies each person who opens an Account. What this means for you',
    patriotAct2:
      'When you open an Account, we will ask for your name, address, date of birth, and other information that will allow us to identify you. We may also ask to see a copy of your driver’s license or other identifying documents.',
    button: 'Got it',
  },
  thankYou: {
    title: 'Thank you',
    subTitle1: 'We’re verifying your information and will email you at ',
    subTitle2: ' within 2 business days.',
    body: 'Your debit card will only be charged after your account is opened.',
    buttons: {
      review: 'Review uploaded documents',
    },
  },
  errorScreen: {
    title: 'There was a technical problem',
    subTitle: "Try refreshing the page. If that doesn't work, email us at **support@northone.com**",
  },
  proofOfIdentityFront: {
    heading: 'Upload a front-facing photo of your proof of identity',
    subHeading: 'Verify your identity with an unexpired front-facing photo of your photo ID. We do not accept scans.',
    viewAcceptedDocsText: 'View all accepted proof of identity types',
  },
  proofOfIdentityBack: {
    heading: 'Upload a back-facing photo of your proof of identity',
    subHeading: 'Verify your identity with an unexpired back-facing photo of your photo ID. We do not accept scans.',
  },
  proofOfIdentityModal: {
    title: 'Proof of identity types',
    preferredMethod: {
      subHeading: 'Preferred validation method:',
      driversLicense: "US State ID or Driver's License",
    },
    other: {
      otherSubHeading: 'Other accepted documents:',
      passport: 'US Passport or Passport Card',
      residentCard: 'US Permanent Resident Card',
      authorizationCard: 'US Employment Authorization Card',
      borderCard: 'US Border Crossing Card',
      military: 'US Military/Armed Forces ID (active or retired)',
      consular: 'Matricula Consular (Consular Identification Card)',
      tribal: 'Tribal ID (Federally recognized)',
    },
    uploadNowButtonText: 'Upload now',
    noneOfTheseButtonText: "I don't have any of these",
  },
  proofOfOwnership: {
    heading: 'Upload your proof of ownership',
    subHeading:
      'Verify your ownership with an articles of incorporation, corporate charter, articles of association, or a certificate of incorporation. We do not accept EIN documents.',
    viewAcceptedDocsText: 'View all accepted proof of ownership types',
  },
  proofOfOwnershipModal: {
    title: 'Proof of ownership types',
    preferred: {
      subHeading: 'Preferred validation method:',
      incorporationArticle: 'Articles of incorporation',
      charter: 'Corporate charter',
      association: 'Articles of association',
      incorporationCertificate: 'Certificate of incorporation',
    },
    other: {
      otherSubHeading: 'Other validation methods:',
      nameRegistration: 'Fictitious name registration',
      partnership: 'Partnership agreement',
      limitedPartnership: 'Limited partnership agreement',
      resolution: 'Resolution of authority',
      operatingAgreement: 'Operating agreement (must be signed)',
      estate: 'Estate papers',
      trust: 'Trust agreement or certification of trust',
      businessLicense: 'Business license',
      governmentCertificate: 'Certificate of good standing from a government entity',
    },
  },
  businessLicense: {
    heading: 'Upload your business license or Doing Business As (DBA)',
    subHeading:
      'Verify your ownership by uploading a document confirming your business name or trade. We do not accept EIN documents.',
    cannotFind: "I can't find these documents",
  },
  DBAModal: {
    title: 'Find your business license or Doing Business As (DBA)',
    subHeading:
      'In order for us to accept proof of your business name registration, we need official documentation showing your business name is registered with the Secretary of State or the County Clerk’s Office.',
    couldInclude: 'This could include, or be called:',
    list: {
      businessLicense: 'Your business license',
      dba: 'Doing Business As (DBA)',
      tradeName: 'Trade name / Fictitious name / Assumed name registration document',
    },
    stillCantFind: "I still can't find these documents",
  },
  uploadAttachment: {
    error:
      'There was an issue uploading your file, ensure your file is PDF, JPG, or PNG format up to {{sizeLimit}}. If the issue persists, ',
    contactSupport: 'contact Customer Care.',
    header: 'Drag and drop your attachment',
    or: 'or',
    browseFiles: 'Browse files',
    acceptedFiles: 'We accept PDF, JPG, and PNG format up to {{sizeLimit}}',
    intercomMessage:
      'To continue with your application, we need a few documents from you. Please upload them as prompted.',
  },
} as const
