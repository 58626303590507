export const businessDetails = {
  expectations: {
    nextUp: 'Next up:',
    header: 'Tell us about your business',
    subHeader: "You'll need the following information",
    bullets: {
      businessName: 'Business name, description, and industry',
      ein: 'Employer Identification Number',
      businessAddressAndPhoneNumber: 'Business address and phone number',
      revenue: 'Revenue estimates for the next 12 months',
    },
    legalDisclaimer1:
      '¹Based on North One user data. Time saved compared to sending the same type of payment at a traditional bank. ',
    learnTimeSaved: 'Learn how we calculate time saved.',
    legalDisclaimer2:
      'IMPORTANT INFORMATION ABOUT PROCEDURES FOR OPENING A NEW CARD ACCOUNT:  To help the government fight the funding of terrorism and money laundering activities, federal law requires all financial institutions to obtain, verify, and record information that identifies each person who opens a Card Account.  What this means for you: When you open a Card Account, we will ask for your name, address, date of birth, and other information that will allow us to identify you. We may also ask to see a copy of your driver’s license or other identifying documents.',
    einModal: {
      header: 'How to find your Employer Identification Number (EIN):',
      bullets: {
        confirmationLetter: 'Check the confirmation letter you received from the IRS when you applied for an EIN.',
        federalTaxReturns:
          'Look at old federal tax returns, official IRS tax notices, business licenses and permits, bank statements, business loan applications, credit reports, or payroll paperwork.',
        irs: 'Contact the IRS if you have lost or misplaced your EIN. You can call the Business & Specialty Tax Line at 800-829-4933.',
      },
    },
  },

  about: {
    title: 'Tell us about your business',
    solepropBusinessNameLabelWithDBA: 'Trade name or DBA',
    solepropBusinessNameLabelWithoutDBA: 'Business name (optional)',
    solepropHasRegisteredBusinessNameLabel:
      'My business name is registered as a trade name or DBA (“Doing Business As”) with my state',
    legalBusinessNameLabel: 'Legal business name',
    tradeNameOrDBALabel: 'Trade name or DBA (optional)',
    tradeNameOrDBAHelpText: 'A trade name or DBA (“Doing Business As”) is another name your company uses to operate.',
    einLabel: 'Employer Identification Number',
    numberOfFullTimeEmployeesLabel: 'Number of full time employees, including yourself',
    yearsInBusinessLabel: 'Years in business',
    yearsInBusinessHelperText: 'Round up to the nearest full year',
  },

  contactInfo: {
    addressAutocompleteLabel: 'Business address',
    addressAutocompletePlaceholder: 'Search business address',
    addressAutocompleteHelperText: 'PO boxes are not accepted',
  },

  revenue: {
    title: "Share your business' expected revenue",
    revenueOriginUSA: 'USA only',
    revenueOriginMultipleCountries: 'Multiple countries',
    expectedRevenueOrigin: 'Expected revenue origin',
    expectedRevenueNext12Months: 'Expected revenue in the next 12 months',
    percentageOfUSRevenue: 'Expected percentage of US revenue',
    percentageOfUSRevenueErrorText: 'Value must be less than 100%',
    expectedRevenueErrorText: 'Expected revenue must be less than 15 digits',
    bestEstimate: 'Enter your best estimate',
  },

  onlinePresence: {
    title: "Link your business' online presence",
    invalidURLMessage: 'Enter a valid URL',
    errorText: 'There was an error reviewing your business',
    otherLabel: 'Other',
    websiteLabel: 'Website (optional)',
    socialMediaLabel: 'Social media (optional)',
    revenueProvidersHeader: 'Select all platforms you use to collect customers payments:',
    otherRevenueProviderLabel: 'What other revenue platform do you use? (optional)',
    reviewingBusiness: 'Reviewing your business',
  },

  solePropDetectedModal: {
    title: 'Sounds like you might be a sole proprietorship',
    subtitle:
      'If you work as a contractor for or run your business through {{businessName}}, you should apply as a sole proprietorship.',
    applyButton: 'Apply as sole proprietorship',
    dismissButton: 'Dismiss',
    businessLogosAltText: 'Logos of business names used by applicants who should apply as sole proprietorships',
  },

  confirmBusinessTypeModal: {
    title: 'Are you sure you’re a sole proprietorship?',
    subtitle:
      'Based on your business name, we think you might be registered as a corporation or partnership. Make sure you’re applying under the right business type before moving forward.',
    updateButton: 'Update business type',
    editButton: 'Edit business name',
  },
} as const
