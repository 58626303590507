export const OWNERSHIP_PERCENTAGE_LOWER_BOUND = 1
export const OWNERSHIP_PERCENTAGE_UPPER_BOUND = 100
export const MINIMUM_CLIENT_AGE = 18
export const MINIMUM_BIRTH_YEAR = 1900
export const DATE_INPUT_FORMAT = 'MM/dd/yyyy'
export const DATE_MONTH_YEAR_FORMAT = 'MM/yy'
export const ISO_DATE_FORMAT = 'yyyy-MM-dd'
export const PHONE_NUMBER_PLACEHOLDER = '(XXX) XXX-XXXX'

export const FACEBOOK_URL = 'https://www.facebook.com/'
export const TWITTER_URL = 'https://www.twitter.com/'
export const LINKEDIN_URL = 'https://www.linkedin.com/in/'
export const INSTAGRAM_URL = 'https://www.instagram.com/'

export const TERMS_OF_SERVICE_URL = 'https://www.northone.com/terms-of-service'
export const DEPOSIT_ACCOUNT_AGREEMENT_URL = 'https://www.northone.com/account-agreement'
export const SCHEDULE_ONBOARDING_WEBINAR_URL = 'https://calendly.com/brandon-n1/your-north-one-onboarding-webinar-1'

export const TIME_SAVED_ARTICLE = 'http://help.northone.com/en/articles/9095567-time-saved-counter'

export const NON_SECURE_TRANSER_PROTOCOL = 'http'
export const NON_SECURE_WEBSITE_PREFIX = `${NON_SECURE_TRANSER_PROTOCOL}://`
export const FACEBOOK_PREFIX = 'facebook.com/'
export const TWITTER_PREFIX = '@'
export const LINKEDIN_PREFIX = 'linkedin.com/in/'
export const INSTAGRAM_PREFIX = '@'

export const OTHER_SOCIAL_MEDIA_PLACEHOLDER = 'Example: yelp.com/biz/your-business'

export const TERMS_AND_CONDITIONS_AGREEMENT_TYPE = 'terms_and_conditions'

export const americanStates = [
  { text: 'Alabama', value: 'AL' },
  { text: 'Alaska', value: 'AK' },
  { text: 'Arizona', value: 'AZ' },
  { text: 'Arkansas', value: 'AR' },
  { text: 'California', value: 'CA' },
  { text: 'Colorado', value: 'CO' },
  { text: 'Connecticut', value: 'CT' },
  { text: 'Delaware', value: 'DE' },
  { text: 'District of Columbia', value: 'DC' },
  { text: 'Florida', value: 'FL' },
  { text: 'Georgia', value: 'GA' },
  { text: 'Hawaii', value: 'HI' },
  { text: 'Idaho', value: 'ID' },
  { text: 'Illinois', value: 'IL' },
  { text: 'Indiana', value: 'IN' },
  { text: 'Iowa', value: 'IA' },
  { text: 'Kansas', value: 'KS' },
  { text: 'Kentucky', value: 'KY' },
  { text: 'Louisiana', value: 'LA' },
  { text: 'Maine', value: 'ME' },
  { text: 'Maryland', value: 'MD' },
  { text: 'Massachusetts', value: 'MA' },
  { text: 'Michigan', value: 'MI' },
  { text: 'Minnesota', value: 'MN' },
  { text: 'Mississippi', value: 'MS' },
  { text: 'Missouri', value: 'MO' },
  { text: 'Montana', value: 'MT' },
  { text: 'Nebraska', value: 'NE' },
  { text: 'Nevada', value: 'NV' },
  { text: 'New Hampshire', value: 'NH' },
  { text: 'New Jersey', value: 'NJ' },
  { text: 'New Mexico', value: 'NM' },
  { text: 'New York', value: 'NY' },
  { text: 'North Carolina', value: 'NC' },
  { text: 'North Dakota', value: 'ND' },
  { text: 'Ohio', value: 'OH' },
  { text: 'Oklahoma', value: 'OK' },
  { text: 'Oregon', value: 'OR' },
  { text: 'Pennsylvania', value: 'PA' },
  { text: 'Rhode Island', value: 'RI' },
  { text: 'South Carolina', value: 'SC' },
  { text: 'South Dakota', value: 'SD' },
  { text: 'Tennessee', value: 'TN' },
  { text: 'Texas', value: 'TX' },
  { text: 'Utah', value: 'UT' },
  { text: 'Vermont', value: 'VT' },
  { text: 'Virginia', value: 'VA' },
  { text: 'Washington', value: 'WA' },
  { text: 'West Virginia', value: 'WV' },
  { text: 'Wisconsin', value: 'WI' },
  { text: 'Wyoming', value: 'WY' },
]

export enum CardFundingFailureReasonEnum {
  INSUFFICIENT_FUNDS = 'INSUFFICIENT_FUNDS',
}

export const SOCURE_SCRIPT_URL = 'https://js.dvnfo.com/devicer.min.js'

export const AUTH0_USER_METADATA_URL = 'https://partnershipsAPI.northone.com/user_metadata'

export const PLAID_OAUTH_STATE_ID_URL_PARAM = 'oauth_state_id'

export const HOTJAR_SITE_ID = 5013462
