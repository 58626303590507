export const disclaimers = {
  standard:
    'North One is a financial technology company, and not a bank. Banking services are provided by The Bancorp Bank, N.A., Member FDIC. The North One Mastercard® Small Business Debit Card is issued by The Bancorp Bank, N.A., Member FDIC, pursuant to license by Mastercard International Incorporated. Mastercard and the circles design are registered trademarks of Mastercard International Incorporated. Card may be used everywhere Mastercard is accepted.',
  funding: {
    agreementClause: '¹Some limitations apply. See your ',
    fundingLink: 'Deposit Account Agreement',
    fundingFollowUp: '  for more details.',
  },
  topUpOptIn:
    'By setting up Account balance top-ups, I, {{applicantNameDisplay}}, on {{dateDisplay}}, authorize North One to automatically debit my designated linked external account for recurring top-ups when my North One Account balance falls below a certain threshold, up to {{topUpsPer30Days}} for {{businessTypeDisplay}} within a 30 day rolling window. This authorization remains in effect until revoked by turning off the feature through the North One platform. I am responsible for any fees charged by my bank for ACH debits.',
}
