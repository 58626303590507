import {
  AlertInformationIcon,
  Body,
  BodySizeEnum,
  Box,
  IconComponent,
  SizeEnum,
  ThemeColor,
} from '@northone/ui-components'
import { ReactElement } from 'react'
import { Pressable } from 'react-native'

import { InformationIconVariant } from './types'

interface ListItemProps {
  children: string | ReactElement
  icon: IconComponent
  iconColor?: ThemeColor
  showInformationIcon?: boolean
  informationIconVariant?: InformationIconVariant
  onClick?: () => void
}

const sxProps = {
  parentContainer: {
    flexDirection: 'row',
    gap: '$2',
    alignItems: 'flex-start',
  },
  textContainer: { flex: 1, flexDirection: 'row', alignItems: 'center' },
  informationIcon: { px: '$1', flexDirection: 'row', alignSelf: 'center' },
}

export const ListItem = ({
  children,
  icon: Icon,
  iconColor,
  showInformationIcon,
  informationIconVariant,
  onClick,
}: ListItemProps) => {
  const informationIconColor = informationIconVariant === 'DARK' ? '$charcoal7' : '$charcoal5'

  return (
    <Box sx={sxProps.parentContainer}>
      <Icon color={iconColor} size={SizeEnum.MD} />

      {onClick ? (
        <Pressable onPress={onClick} style={{ flex: 1, flexDirection: 'row', alignItems: 'center' }}>
          <Body size={BodySizeEnum.MD} color={'$charcoal5'}>
            {children}
            {showInformationIcon ? (
              <Box sx={sxProps.informationIcon}>
                <AlertInformationIcon size={SizeEnum.SM} color={informationIconColor} />
              </Box>
            ) : null}
          </Body>
        </Pressable>
      ) : (
        <Box sx={sxProps.textContainer}>
          <Body size={BodySizeEnum.MD} color={'$charcoal5'}>
            {children}
            {showInformationIcon ? (
              <Box sx={sxProps.informationIcon}>
                <AlertInformationIcon size={SizeEnum.SM} color={informationIconColor} />
              </Box>
            ) : null}
          </Body>
        </Box>
      )}
    </Box>
  )
}
